import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

const AboutUs = () => {
    return (<>

        <div>
            <div id="skip-link">
                <a href="#main-content" className="element-invisible element-focusable">Skip to main content</a>
            </div>
            <div className="body-innerwrapper">
            <Header/>
                {/* <div className="region region-banner">
                   
                    <section id="block-views-inner-banner-block" className="block block-views clearfix">
                     
                        <div className="view view-inner-banner view-id-inner_banner view-display-id-block view-dom-id-186d9d866e192631a3bfd928e12bd498">
                           
                            <div className="view-content">
                            
                                <div className="skin-default">
                                    
                                    <div id="views_slideshow_cycle_main_inner_banner-block_1" className="views_slideshow_cycle_main views_slideshow_main"><div id="views_slideshow_cycle_teaser_section_inner_banner-block_1" className="views-slideshow-cycle-main-frame views_slideshow_cycle_teaser_section">
                                       
                                        <div id="views_slideshow_cycle_div_inner_banner-block_1_0" className="views-slideshow-cycle-main-frame-row views_slideshow_cycle_slide views_slideshow_slide views-row-1 views-row-first views-row-odd">
                                          
                                            <div className="views-slideshow-cycle-main-frame-row-item views-row views-row-0 views-row-odd views-row-first">
                                            
                                                <div className="views-field views-field-field-slide-image">
                                                 
                                                    <div className="field-content">
                                                       
                                                        <img typeof="foaf:Image" className="img-responsive" src="sites/default/files/styles/banner_image_side_crop/public/arogyadhama%20boards%203ft%202ft%20low%20resolution5f893.jpg?itok=FTTSotXU" width={1400} height={653} alt />
                                                        
                                                        
                                                        </div>
                                                        
                                                        </div>
                                                        
                                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div> */}
                <div className="main-container container" style={{width:'100%',margin:0}}>
                    <header role="banner" id="page-header">
                    </header> {/* /#page-header */}
                    <div className="row">
                        <section className="col-sm-12">
                            <a id="main-content" />
                            <h1 style={{textAlign:'center'}}>About Sophia</h1>
                            <div className="region region-content">
                                <section id="block-system-main" className="block block-system clearfix">
                                    <article id="node-51" className="node node-page clearfix" about="/about-sushrutha" typeof="foaf:Document">
                                        <header>
                                            <span property="dc:title" content="About Sophia" className="rdf-meta element-hidden" />    </header>
                                        <div className="field field-name-body field-type-text-with-summary field-label-hidden"><div className="field-items"><div className="field-item even" property="content:encoded"><p className="text-center">Sophia college of Nursing  (SCN), Tumkur was established in 2024 with the prime objective of providing effective & efficient education in nursing, creating well qualified and competent students to cater to the rising demand of such personnel across the globe. The college is a unit of Sophia Group of Institutions. It is one of the forefront nursing institutes in India and is counted among the top-rated Colleges in Karnataka with an excellent academic track record. It has a department with ongoing research on nursing practice. The students are given hands-on training by excellent teaching staff. The college grants undergraduate and postgraduate courses in nursing and specialisation in medical-surgery, gynaecology, community health, psychiatric, paediatric and so on. It provides excellent infrastructure and facilities to the students.</p>
                                        </div></div></div>  </article>
                                </section>
                            </div>
                        </section>
                    </div>
                </div>
                <div className="region region-content-bottom">
                    <section id="block-block-30" className="block block-block clearfix">
                        <h2 className="block-title">Accreditations</h2>
                        <ul>
                            <li>Approved by Government of Karnataka</li>
                            <li>Afﬁliated to Rajiv Gandhi University of Health Sciences, Bengaluru</li>
                            <li>Approved by Karnataka State Diploma Nursing Exam Board, Bengaluru</li>
                            <li>Recognized &amp; Approved by the Indian Nursing Council, New Delhi</li>
                            <li>Recognized &amp; Approved by the Karnataka State Nursing Council,  Bengaluru</li>
                        </ul>
                    </section>
                    {/* <section id="block-block-22" className="block block-block clearfix">
                        <div className="row d-flex align-items-center">
                            <div className="col-sm-5 title">
                                <h2 className="block-title text-center">Our Visionary Leaders</h2>
                                <p className="text-center">Dr.H.R.Nagendra is the founder and leading light of our institution. He is well known for his social contributions and our nation has honoured him by awarding Padma Shri.</p>
                                <p className="text-center">Dr.B.R.Ramakrishna is a well-known personality in Ayurveda and Yoga in both national and international level. He has served many reputed positions in the Karnataka Govt and the Govt of India.</p>
                            </div>
                            <div className="col-sm-7 images">
                                <div className="col-sm-6">
                                    <img className="img-responsive" src="sites/all/themes/sushrutha/images/dr-hr-nagendra.jpg" />
                                    <h3>Dr.H.R.Nagendra</h3>
                                    <p>M.E.Ph.D<br />Founder and President</p>
                                    <a className="small" href="dr-h-r-nagendra.html">Know more</a>
                                </div>
                                <div className="col-sm-6">
                                    <img className="img-responsive" src="sites/all/themes/sushrutha/images/ramakrishna.jpg" />
                                    <h3>Dr.B.R.Ramakrishna</h3>
                                    <p>BSAM. BAMS. MD(Ayu) MSc &amp; PhD(Yoga)<br />Director</p>
                                    <a className="small" href="dr-b-r-ramakrishna.html">Know more</a>
                                </div>
                            </div>
                        </div>
                    </section> */}
                    <section id="block-block-23" className="block block-block clearfix">
                        <div className="col-sm-3"><img className="img-responsive" src="images/principal.jpeg" />
                        </div>
                        <div className="col-sm-9 text-on-right">
                            <h2>Our Principal's Message</h2>
                            <p>Dear Nursing Students,<br/>
                            Welcome to an incredible journey toward becoming skilled and compassionate healthcare professionals. Nursing is not just a career; it is a calling that requires dedication, empathy, and a commitment to continuous learning. As you navigate through your studies and clinical experiences, remember that you are preparing to make a profound difference in the lives of others.</p>

                            <p>I encourage you to embrace every opportunity to learn and grow. Each course, each clinical rotation, and each patient interaction will shape you into a nurse who can provide both exceptional care and genuine comfort. Ask questions, seek understanding, and never hesitate to support one another. In nursing, teamwork and collaboration are key to success.
</p>
                            <p>Remember, while the challenges you may face are real, so is your resilience and capacity for greatness. You are the future of healthcare, and the world needs nurses like you compassionate, knowledgeable, and unwavering in the face of difficulty.</p>
                            <div className="text-block">
                                <h4><strong>Yours </strong></h4>
                                <p>Principal</p>
                                {/* M.Sc(N).PhD<br /> */}
                            </div>
                        </div>
                    </section>
                    <section id="block-block-24" className="block block-block clearfix">
                        <div className="col-sm-6 col color">
                            <div className="col-sm-3 compli-color outer-div text-center">
                                <div className="inner-div">
                                    <img src="sites/all/themes/sushrutha/images/icon-vision.png" />
                                    <h3 className="white-font">Our Vision</h3>
                                </div>
                            </div>
                            <div className="col-sm-9 outer-div vertical-center">
                                <div className="inner-div">
                                    <p className="lead white-font">To develop in to a model institute imparting, quality education in Nursing Sciences to produce competent and creative Nurses for building a Dynamic & Healthy Nation.

</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col color">
                            <div className="col-sm-3 text-center">
                                <img src="sites/all/themes/sushrutha/images/icon-mission.png" />
                                <h3 className="white-font">Our Mission</h3>
                            </div>
                            <div className="col-sm-9 compli-color" style={{minHeight:261}}>
                                <div className="white-font">
                               <p style={{color:'#fff'}}>To Serving our students by teaching them problem solving, leadership. teamwork skills and value of commitment to quality, ethical behavior. integrity of character, respect for One another for personal and professional growth. Serving as catalyst in bringing out the best in each student in the field of Nursing Science Ensuring a congenial environment of academic excellence in the campus through teaching ,training, guiding and exposing our students for higher learning in Nursing Science
                               </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col color">
                            <div className="col-sm-3 compli-color text-center">
                                <img src="sites/all/themes/sushrutha/images/icon-values.png" />
                                <h3 className="white-font">Our Values</h3>
                            </div>
                            <div className="col-sm-9">
                                <p className="white-font"><span className="label">C</span> Compassion</p>
                                <p className="white-font"><span className="label">I</span>Integrity</p>
                                <p className="white-font"><span className="label">E</span>Excellence</p>
                                <p className="white-font"><span className="label">C</span> Collaboration</p>
                                <p className="white-font"><span className="label">R</span> Resillience</p>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="region region-content-bottom-bottom">
                    <section id="block-block-4" className="block block-block clearfix">
                        <p className="lead text-center">"Nursing is an art: and if it is to be made an art, it requires an exclusive devotion as hard a preparation, as any painter's or sculptor's work."</p>
                        <h3 className="text-center"><em> - Florence Nightingale</em><br /><span className="small">Founder of Modern Nursing</span></h3>
                    </section>
                    <section id="block-block-9" className="block block-block clearfix">
                        <div className="center">
                            <p className="lead">Are you ready to earn your nursing degree?
                                <Link className="btn" to="/Contact">Contact Us to know more</Link></p>
                        </div>
                    </section>
                </div>
             <Footer/>
            </div>
        </div>

    </>)
}
export default AboutUs;