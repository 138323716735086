import React from "react";
import { Link } from "react-router-dom";
const Header = () => {
    return (
        <>

            <header id="navbar" role="banner" className="navbar container navbar-default">
                <div className="region region-navigation">
                    <section id="block-block-31" className="block block-block clearfix">
                        <h3>Admission / Enquiry opened for BSc Nursing</h3>
                        <Link className="btn" to="/admission">Click here to apply for the required course</Link>
                    </section>
                    <section id="block-block-5" className="block block-block clearfix" style={{borderTop:'1px solid #fff', backgroundColor:'#001c55'}}>
                        <div className="phone-email-block">
                            <p><img src="/sites/all/themes/sushrutha/images//icon-phone-blue.png" />
                           <a href="tel:7795840617" style={{color:'#fff'}}>+91 7795840617</a>   |  <a href="tel:9743216678" style={{color:'#fff'}}>+91 9743216678
                           </a></p>
                            <p><img src="/sites/all/themes/sushrutha/images//icon-email-blue.png" />
                            <a href="mailto:sophiacollegeofnursing@gmail.com" style={{color:'#fff'}}>
                                sophiacollegeofnursing@gmail.com
                            </a>
                            </p>
                        </div>
                    </section>
                </div>
                {/* container */}
                <div className="">
                    <div className="navbar-header">
                        <div className="burger-bar">
                            <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbar-collapse">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                            </button>
                        </div>
                        <Link className="logo navbar-btn pull-left" to="/" title="Home">
                            <img src="./images/logo.png" alt="Home" />
                        </Link>
                        <div className="navbar-collapse collapse" id="navbar-collapse">
                            <nav role="navigation" style={{padding:'10px'}}>
                                <ul className="menu nav navbar-nav">
                                    
                                    <li className="first leaf"><Link to="/">Home</Link></li>
                                    <li className="leaf"><Link to="/about">About</Link></li>
                                    <li className="expanded dropdown"><a to="/courses" className="dropdown-toggle" data-toggle="dropdown">Courses <span className="caret" /></a>
                                        <ul className="dropdown-menu">
                                            {/* <li className="first leaf"><a href="/msc-nursing" title>M.Sc.Nursing</a></li> */}
                                            <li className="leaf"><Link to="/bscnursing" title>B.Sc.Nursing</Link></li>
                                            {/* <li className="last leaf"><a href="/diploma-general-nursing-midwifery-gnm" title>G.N.M.</a></li> */}
                                        </ul>
                                        </li>
                                    <li className="expanded dropdown">
                                        <span title className="dropdown-toggle nolink" data-toggle="dropdown" tabIndex={0}>College <span className="caret" /></span>                                        
                                        <ul className="dropdown-menu">                                        
                                        <li className="first leaf"><Link to="/facilities">Facilities</Link></li>
                                        <li className="last leaf"><Link to="/campus">Campus</Link></li>
                                    </ul>

                                    </li>

                                    <li className="leaf"><Link to="/admission">Admission</Link></li>

                                    <li className="leaf"><Link to="/photos">Photos</Link></li>

                                    {/* <li className="leaf"><a href="/news-events">News &amp; Events</a></li> */}
                                    <li className="leaf"><Link to="/career">Career</Link></li>

                                    <li className="last leaf"><Link to="/contact">Contact</Link></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;