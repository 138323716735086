import React from "react";

const Footer = () => {
    return (
        <footer className="footer container" style={{ width: '100%' }}>
            <div className="region region-footer">
                <section id="block-block-15" className="block block-block clearfix">
                    <a href="/"><img className="img-responsive" src="./images/sen-logo.png" style={{ backgroundColor: '#fff', borderRadius: '20px', width: '200px' }} /></a>


                </section>
                <section id="block-menu-menu-footer-menu" className="block block-menu clearfix">
                    <h2 className="block-title">Quick Links</h2>
                    <ul className="menu nav">
                        <li className="first leaf"><a href="/" title>Home</a></li>
                        <li className="last leaf">
                            <a href="/about-sushrutha" title>About</a>
                            </li>
                    </ul>
                </section>
                <section id="block-block-14" className="block block-block clearfix">
                    <h2 className="block-title">Contact</h2>
                    <div className="address">
                        <img src="/sites/all/themes/sushrutha/images/icon-marker.png" />
                        {/* <img src="/sites/all/themes/sushrutha/images/icon-marker.png" /> */}
                      <p>Banashankari 2nd <br/>
stage Amarjoythinagar<br/>
Hemavathi Extension<br/>
Tumkur -572105 </p>
                    </div>
                    <div className="phone">
                        <img src="/sites/all/themes/sushrutha/images/icon-phone.png" />
                        <p>+91 9743216678
                            <br />
                            +91 7795840617</p>
                    </div>
                    <div className="email-id">
                        <img src="/sites/all/themes/sushrutha/images/icon-email.png" />
                        <p>sophiacollegeofnursing@gmail.com</p>
                    </div>
                </section>
                <section id="block-block-1" className="block block-block clearfix">
                    <p>Copyright&nbsp;© {new Date().getFullYear()} Sophia  Institute of Nursing. All Rights Reserved.</p>
                    <ul>
                        <li><a href="/privacy-policy">Privacy Policy</a></li>
                        <li><a href="/terms-conditions">Terms &amp; Conditions</a></li>
                    </ul>
                </section>
                {/* <section id="block-menu-menu-login-logout" className="block block-menu clearfix">
                    <ul className="menu nav"><li className="first last leaf"><a href="/user/login" title>Login</a></li>
                    </ul>
                </section> */}
            </div>
        </footer>
    )
}

export default Footer;