import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Bscnursing = () => {
    return (


        <div>

            <div className="body-innerwrapper">
            <Header/>
                <div className="region region-banner">
                    <section id="block-views-inner-banner-block" className="block block-views clearfix">
                        {/* <div className="view view-inner-banner view-id-inner_banner view-display-id-block view-dom-id-729d3f874e000047fc50a6337297b51b">
                            <div className="view-content">
                                <div className="skin-default">
                                    <div id="views_slideshow_cycle_main_inner_banner-block_1" className="views_slideshow_cycle_main views_slideshow_main"><div id="views_slideshow_cycle_teaser_section_inner_banner-block_1" className="views-slideshow-cycle-main-frame views_slideshow_cycle_teaser_section">
                                        <div id="views_slideshow_cycle_div_inner_banner-block_1_0" className="views-slideshow-cycle-main-frame-row views_slideshow_cycle_slide views_slideshow_slide views-row-1 views-row-first views-row-odd">
                                            <div className="views-slideshow-cycle-main-frame-row-item views-row views-row-0 views-row-odd views-row-first">
                                                <div className="views-field views-field-field-slide-image">        <div className="field-content"><img typeof="foaf:Image" className="img-responsive" src="https://www.sushruthacollegeofnursing.com/sites/default/files/styles/banner_image_side_crop/public/basic-bsc-nursing-innerslide.jpg?itok=6S8F7Oon" width={1400} height={653} alt /></div></div></div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </section>
                </div>
                <div className="main-container container" style={{width:'100%',margin:0}}>
                    <header role="banner" id="page-header">
                    </header> {/* /#page-header */}
                    <div className="row">
                        <section className="col-sm-12">
                            <ol className="breadcrumb"><li><a href="/courses" className="active-trail">Courses</a></li>
                                <li className="active">B.Sc.Nursing</li>
                            </ol>      <a id="main-content" />
                            <h1 className="page-header">B.Sc.Nursing</h1>
                            <div className="region region-content">
                                <section id="block-system-main" className="block block-system clearfix">
                                    <article id="node-65" className="node node-page clearfix" about="/bscnursing" typeof="foaf:Document">
                                        <header>
                                            <span property="dc:title" content="B.Sc.Nursing" className="rdf-meta element-hidden" />    </header>
                                        <div className="field field-name-body field-type-text-with-summary field-label-hidden"><div className="field-items"><div className="field-item even" property="content:encoded"><h3>ELIGIBILITY</h3>
                                            <ul>
                                                <li>The minimum age for admission shall be 17 years on 31st December of the year in which admission is sought.</li>
                                                <li>Minimum education:
                                                    <ul>
                                                        <li>10+2 class passed with Science (PCB) &amp; English Core/English Elective with aggregate of 50% marks from recognized board under AISSCE/CBSE/ICSE/SSCE/HSCE or other equivalent Board.</li>
                                                    </ul>
                                                </li>
                                                <li>Student shall be medically fit.</li>
                                                <li>Students appearing in 10+2 examination in Science conducted by National Institute of Open School with 50% marks.</li>
                                                <li>Student shall be admitted once in a year.</li>
                                            </ul>
                                            <h3>SUBJECTS TAUGHT</h3>
                                            (These Subjects are subject to change as per the revised syllabus)
                                            <h4>I Year B.Sc. Nursing</h4>
                                            <ul>
                                                <li>English</li>
                                                <li>Kannada</li>
                                                <li>Anatomy</li>
                                                <li>Physiology</li>
                                                <li>Biochemistry</li>
                                                <li>Nursing Foundations</li>
                                                <li>Psychology</li>
                                                <li>Microbiology</li>
                                                <li>Introduction to computers</li>
                                                <li>Nutrition</li>
                                            </ul>
                                            <h4>II Year B.Sc. Nursing</h4>
                                            <ul>
                                                <li>Sociology</li>
                                                <li>Pharmacology</li>
                                                <li>Medical &amp; Surgical Nursing (adult including geriatrics) I</li>
                                                <li>Community Health Nursing I</li>
                                                <li>Statistics &amp; Introduction to Nursing Research</li>
                                                <li>Pathology</li>
                                                <li>Genetics</li>
                                                <li>Communication and Education technology</li>
                                            </ul>
                                            <h4>III Year B.Sc. Nursing</h4>
                                            <ul>
                                                <li>Midwifery and Obstetrics I</li>
                                                <li>Medical &amp; Surgical Nursing (adult including geriatrics) II</li>
                                                <li>Child Health Nursing</li>
                                                <li>Mental Health nursing</li>
                                                <li>Co curricular activities</li>
                                            </ul>
                                            <h4>IV Year B.Sc. Nursing</h4>
                                            <ul>
                                                <li>Midwifery and Obstetrics II</li>
                                                <li>Community Health Nursing II</li>
                                                <li>Nursing Administration</li>
                                                <li>Co curricular activities</li>
                                            </ul></div></div></div>  </article>
                                </section>
                            </div>
                        </section>
                    </div>
                </div>
                <div className="region region-content-bottom-bottom">
                    <section id="block-block-9" className="block block-block clearfix">
                        <div className="center">
                            <p className="lead">Are you ready to earn your nursing degree?
                                <a className="btn" href="/contact">Contact Us to know more</a></p>
                        </div>
                    </section>
                </div>
              <Footer/>
            </div>
        </div>

    )
}

export default Bscnursing
