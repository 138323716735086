import React from "react";
import { Link } from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";

const Home = () => {
    return (<>
        <div>
            <div id="skip-link">
                <a href="#main-content" className="element-invisible element-focusable">Skip to main content</a>
            </div>
            <div className="body-innerwrapper">
                <Header />
                <div className="region region-banner">
                    {/* <section id="block-views-slideshow-block" className="block block-views clearfix">
                        <div className="view view--slideshow view-id-_slideshow view-display-id-block view-dom-id-f0d5c0d5c43486d9aefa6d8248afb6b3">
                            <div className="view-content">
                                <div className="skin-default">
                                    <div id="views_slideshow_cycle_main__slideshow-block_1" className="views_slideshow_cycle_main views_slideshow_main"><div id="views_slideshow_cycle_teaser_section__slideshow-block_1" className="views-slideshow-cycle-main-frame views_slideshow_cycle_teaser_section">
                                        <div id="views_slideshow_cycle_div__slideshow-block_1_0" className="views-slideshow-cycle-main-frame-row views_slideshow_cycle_slide views_slideshow_slide views-row-1 views-row-first views-row-odd" aria-labelledby="views_slideshow_pager_field_item_bottom__slideshow-block_1_0">
                                            <div className="views-slideshow-cycle-main-frame-row-item views-row views-row-0 views-row-odd views-row-first">
                                                <div className="views-field views-field-field-slide-image">
                                                    <div className="field-content">
                                                        <img typeof="foaf:Image" className="img-responsive" src="sites/default/files/slide-intro_0.jpg" width={1400} height={532} alt /></div></div>
                                                <div className="views-field views-field-body">        <div className="field-content"><div className="col-sm-6 text-on-left">
                                                    <p className="h1 text-left animate__animated animate__fadeInLeft first">Sophia - where tradition meets modern medicine</p>
                                                    <p className="text-left animate__animated animate__fadeInDown second">Get admission to Bangalore's leading Nursing Institute</p>
                                                    <Link className="btn animate__animated animate__fadeInUp second" to="/admission">Apply Now</Link>
                                                </div>
                                                    <div className="col-sm-6">
                                                        <img className="animate__animated animate__fadeInRight" src="sites/all/themes/sushrutha/images/steth.png" />
                                                    </div>
                                                </div></div></div>
                                        </div>
                                        <div id="views_slideshow_cycle_div__slideshow-block_1_1" className="views-slideshow-cycle-main-frame-row views_slideshow_cycle_slide views_slideshow_slide views-row-2 views_slideshow_cycle_hidden views-row-last views-row-even" aria-labelledby="views_slideshow_pager_field_item_bottom__slideshow-block_1_1">
                                            <div className="views-slideshow-cycle-main-frame-row-item views-row views-row-0 views-row-odd">
                                                <div className="views-field views-field-field-slide-image">        <div className="field-content"><img typeof="foaf:Image" className="img-responsive" src="sites/default/files/slide-02.jpg" width={1400} height={532} alt /></div></div>
                                                <div className="views-field views-field-body">        <div className="field-content"><div className="col-sm-6 text-on-left">
                                                    <p className="h2 text-left animate__animated animate__fadeInLeft first">Professional Respect<br />Job Security<br />Exceptionally Gratifying</p>
                                                    <p className="text-left animate__animated animate__fadeInDown second">Nursing is a Responsible and Rewarding Career</p>
                                                    <Link className="btn animate__animated animate__fadeInUp second" to="/admission">Apply Now</Link>
                                                </div></div></div></div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="views-slideshow-controls-bottom clearfix">
                                        <div id="widget_pager_bottom__slideshow-block_1" className="views-slideshow-pager-fields widget_pager widget_pager_bottom views_slideshow_pager_field">
                                            <div id="views_slideshow_pager_field_item_bottom__slideshow-block_1_0" className="views-slideshow-pager-field-item views_slideshow_pager_field_item views-row-odd views-row-first" aria-controls="views_slideshow_cycle_div__slideshow-block_1_0">
                                            </div>
                                            <div id="views_slideshow_pager_field_item_bottom__slideshow-block_1_1" className="views-slideshow-pager-field-item views_slideshow_pager_field_item views-row-even views-row-last" aria-controls="views_slideshow_cycle_div__slideshow-block_1_1">
                                            </div>
                                        </div>
                                        <div id="views_slideshow_controls_text__slideshow-block_1" className="views-slideshow-controls-text views_slideshow_controls_text">
                                            <span id="views_slideshow_controls_text_previous__slideshow-block_1" className="views-slideshow-controls-text-previous views_slideshow_controls_text_previous">
                                                <a href="#" rel="prev">Previous</a>
                                            </span>
                                            <span id="views_slideshow_controls_text_pause__slideshow-block_1" className="views-slideshow-controls-text-pause views_slideshow_controls_text_pause  views-slideshow-controls-text-status-play"><a href="#">Pause</a></span>
                                            <span id="views_slideshow_controls_text_next__slideshow-block_1" className="views-slideshow-controls-text-next views_slideshow_controls_text_next">
                                                <a href="#" rel="next">Next</a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}
<div id="myCarousel" className="carousel slide" data-ride="carousel">
  {/* Indicators */}
  <ol className="carousel-indicators">
    <li data-target="#myCarousel" data-slide-to={0} className="active" />
    <li data-target="#myCarousel" data-slide-to={1} />
    {/* <li data-target="#myCarousel" data-slide-to={2} /> */}
  </ol>
  {/* Wrapper for slides */}
  <div className="carousel-inner">
    <div className="item active">
      <img src="./images/banner/slide-3.jpeg" alt="Los Angeles" style={{width: '100%'}} />
      <div className="carousel-caption" style={{paddingBottom:"60px"}}>
        <h3>Sophia Where knowledge Meets Wisdom Get Admission in Tumkur Leading Institute.</h3>
        {/* <p>LA is always so much fun!</p> */}
      </div>
    </div>
    <div className="item">
      <img src="./images/banner/slide-4.jpeg" alt="Chicago" style={{width: '100%'}} />
      <div className="carousel-caption" style={{paddingBottom:"60px"}}>
        <h3>Where profession Meet Respects Nusing is a Responsible and Rewarding Career.</h3>
        {/* <p>LA is always so much fun!</p> */}
      </div>
    </div>
    {/* <div className="item">
      <img src="ny.jpg" alt="New York" style={{width: '100%'}} />
      <div className="carousel-caption">
        <h3>New York</h3>
        <p>We love the Big Apple!</p>
      </div>
    </div> */}
  </div>
  {/* Left and right controls */}
  <a className="left carousel-control" href="#myCarousel" data-slide="prev">
    <span className="glyphicon glyphicon-chevron-left" />
    <span className="sr-only">Previous</span>
  </a>
  <a className="right carousel-control" href="#myCarousel" data-slide="next">
    <span className="glyphicon glyphicon-chevron-right" />
    <span className="sr-only">Next</span>
  </a>
</div>



                </div>
                <div className="main-container container" style={{ width: "100%" }}>
                    <header role="banner" id="page-header">
                    </header> {/* /#page-header */}
                    <div className="row">
                        <section className="col-sm-12">
                            <a id="main-content" />
                            <h1 className="page-header">
                                Welcome to Sophia college of Nursing
                            </h1>
                            <div className="region region-content">
                                <article id="node-29" className="node node-page clearfix" about="/welcome-sushrutha-institute-nursing" typeof="foaf:Document">
                                    <header>
                                        <span property="dc:title" content="
Welcome to Sophia college of Nursing
" className="rdf-meta element-hidden" />    </header>
                                    <div className="field field-name-body field-type-text-with-summary field-label-hidden"><div className="field-items"><div className="field-item even" property="content:encoded"><div className="banner-overlap">
                                        <div className="col-sm-3 col">
                                            <h3>Courses</h3>
                                            <p className="small">Know more about the courses offered at Sophia
                                                <Link to="/bscnursing"><img src="sites/all/themes/sushrutha/images/icon-arrow-blue.png" /></Link>
                                            </p>
                                        </div>
                                        <div className="col-sm-3 col">
                                            <h3>Admission</h3>
                                            <p className="small">Get informtaion about the admission procedures
                                                <Link to="/admission"><img src="sites/all/themes/sushrutha/images/icon-arrow-blue.png" /></Link>
                                            </p>
                                        </div>
                                        <div className="col-sm-3 col">
                                            <h3>Facilities</h3>
                                            <p className="small">Explore the facilities provided at the campus
                                                <Link to="/facilities"><img src="sites/all/themes/sushrutha/images/icon-arrow-blue.png" /></Link>
                                            </p>
                                        </div>
                                        <div className="col-sm-3 col">
                                            <h3>Campus</h3>
                                            <p className="small">Find out more about our campus and activities
                                                <Link to="/campus"><img src="sites/all/themes/sushrutha/images/icon-arrow-blue.png" /></Link>
                                            </p>
                                        </div>
                                    </div></div></div></div>  </article>
                            </div>
                        </section>
                    </div>
                </div>
                <div className="region region-content-bottom">
                    <section id="block-block-21" className="block block-block clearfix">
                        <div className="content-block">
                            <div className="row bottom-space">
                                <div className="col-sm-6 image image-right">
                                    <img className="img-responsive enlarge" src="./images/welcome-to-sophia.jpg" />
                                </div>
                                <div className="col-sm-6 text text-on-left">
                                    <h2>
                                        Welcome to Sophia college of Nursing
                                    </h2>
                                    <p>Sophia  is the name that connect with knowledge, power, and bravery<br />
                                        The Institute offers courses from Primary Education to Post Graduation in Nursing.</p>
                                    <Link className="btn" to="/about">Know more</Link>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 image">
                                    <img className="img-responsive" src="/images/why-to -choose-career.jpg" />
                                </div>
                                <div className="col-sm-6 text text-on-right">
                                    <h2>Why to choose a career in Nursing?</h2>
                                    <p>Nursing is a profession dedicated to caring for others and only few careers are as personally rewarding as nursing.<br />
                                        Choosing to become a nurse can provide you with a job that provides personal fulfillment, job security and flexibility.</p>
                                    <Link className="btn" to="/career">Know more</Link>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="block-block-17" className="block block-block clearfix">
                        <h2 className="block-title">Courses we offer</h2>
                        <div className="row-1 prod-row">
                            {/* <div className="col-sm-4 col">
                                <img className="img-responsive" src="sites/all/themes/sushrutha/images/courses-thumb-msc.jpg" />
                                <div className="text-on-bottom">
                                    <h3><a href="msc-nursing.html">M.Sc. Nursing</a></h3>
                                </div>
                            </div> */}
                            {/*--
<div class="col-sm-3 col">
<img class="img-responsive" src="/sites/all/themes/sushrutha/images/courses-thumb-post-basic.jpg">
<div class="text-on-bottom">
<h3><a href="/post-basic-bsc-nursing">Post Basic B.Sc. Nursing</a></h3>
</div>
</div>
-*/}
                            <div className="col-sm-4 col">
                                <img className="img-responsive" src="./images/bsc-nursing.jpeg" />
                                <div className="text-on-bottom">
                                    <h3><Link to="/bscnursing">B.Sc. Nursing</Link></h3>
                                </div>
                            </div>
                            {/* <div className="col-sm-4 col">
                                <img className="img-responsive" src="sites/all/themes/sushrutha/images/courses-thumb-diploma.jpg" />
                                <div className="text-on-bottom">
                                    <h3><a href="diploma-general-nursing-midwifery-gnm.html">Diploma in General Nursing &amp; Midwifery (G.N.M.)</a></h3>
                                </div>
                            </div> */}
                        </div>
                    </section>
                </div>
                <div className="region region-content-bottom-bottom">
                    <section id="block-block-20" className="block block-block clearfix">
                        <div className="text-block" id="text-block-1">
                            <div className="row">
                                <p className="text-center text-uppercase"><small>Our Specialities</small></p>
                                <p className="lead text-center">We believe in Discipline, Quality and Professionalism. <br />Our vision is to focus on the students, giving them education and skills so they can perform better in both profession and in life.</p>
                                <div className="blocks-within-textblock">
                                    <div className="col-sm-4">
                                        <img className="center-block" src="sites/all/themes/sushrutha/images/icon-blue-content-bottom.png" />
                                        <p className="text-center">Discipline and routine are the keys to success, especially in education. A disciplined person can stay focused, manage their time effectively, and consistently work toward their goals. This mindset helps them overcome obstacles and achieve long-term success in life.</p>
                                    </div>
                                    <div className="col-sm-4">
                                        <img className="center-block" src="sites/all/themes/sushrutha/images/icon-blue-2-content-bottom.png" />
                                        <p className="text-center">Quality education is the foundation of success. By prioritizing excellence in our teaching, we ensure that our students are well-prepared and stand out ahead of their competitors.</p>
                                    </div>
                                    <div className="col-sm-4">
                                        <img className="center-block" src="sites/all/themes/sushrutha/images/icon-blue-3-content-bottom.png" />
                                        <p className="text-center">In today’s era of global opportunities, our education system is designed to equip students with the skills and knowledge needed to excel on the world stage. We are preparing professionals ready to seize global opportunities and thrive in a competitive environment.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="block-block-4" className="block block-block clearfix">
                        <p className="lead text-center">"Nursing is an art: and if it is to be made an art, it requires an exclusive devotion as hard a preparation, as any painter's or sculptor's work."</p>
                        <h3 className="text-center"><em> - Florence Nightingale</em><br /><span className="small">Founder of Modern Nursing</span></h3>
                    </section>
                    <section id="block-block-9" className="block block-block clearfix">
                        <div className="center">
                            <p className="lead">Are you ready to earn your nursing degree?
                                <Link className="btn" to="contact">Contact Us to know more</Link></p>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        </div>

    </>)
}
export default Home;